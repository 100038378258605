<template>
  <div id="VideoList">
    <Header></Header>
    <div class="position_box">
      <div class="center_position">
        <img
          class="backImg"
          @click="backHome"
          src="../../assets/img/back_home.png"
          alt=""
        />
        <div class="home" @click="backHome">首页</div>
        <div class="current">当前位置：</div>
        <div class="home1" @click="backHome">首页></div>
        <div class="video_center">视频中心</div>
      </div>
    </div>
    <div class="content_box">
      <List :types="1" :carList="videoData"></List>
    </div>
    <div class="pagetion_BrandZoneList" >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="limit"
        background
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <div class="el_queding">确定</div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header";
import List from "../../components/list";
import { getVideoList } from "../../http/api";
export default {
  name: "",
  props: {},
  components: {
    Header,
    List,
  },
  data() {
    return {
      videoData: [],
      page: 1,
      limit: 9,
      total:0,
    };
  },
  methods: {
      handleSizeChange(){},
      handleCurrentChange(page){
          this.page = page
          this.getvideoData(this.page,this.limit)
      },
    getvideoData(page, limit) {
      getVideoList({
        page: page,
        limit: limit,
      }).then((res) => {
        console.log(res);
        this.videoData = res.data.data;
        this.total = res.data.total
      });
    },
    backHome() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.getvideoData(this.page, this.limit);
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#VideoList {
  width: 100%;
  .position_box {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #e5e5e5;
    .center_position {
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      .backImg {
        width: 30px;
        height: 30px;
        display: block;
        cursor: pointer;
      }
      // <div class="home">首页</div>
      // <div class="current">当前位置：</div>
      // <div class="home1">首页></div>
      // <div class="video_center">视频中心</div>
      .home {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #666666;
        margin: 0 30px 0 20px;
        cursor: pointer;
      }
      .current {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #666666;
        cursor: pointer;
      }
      .home1 {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #666666;
        cursor: pointer;
      }
      .video_center {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        cursor: pointer;
        color: #225928;
      }
    }
  }
  .content_box {
    width: 100%;
    margin-top: 50px;
    min-height: 650px;
  }
  .pagetion_BrandZoneList {
    width: 1200px;
    height: 46px;
    margin: 52px auto 162px auto;
    display: flex;
    justify-content: center;
    .el_queding {
      width: 80px;
      height: 46px;
      border: 1px solid #e5e5e5;
      text-align: center;
      line-height: 46px;
      font-size: 15px;
      color: #999999;
      margin-left: 14px;
      cursor: pointer;
    }
  }
}
</style>